const imageTime = 10000;
const transitionTime = 500;
const bufferTime = 1500;
const modalTransitionTime = 200;

let interval;

function importAll(r) {
  return r.keys().map(r);
}

function getImageUrls() {
  const images = importAll(require.context('../images/generated/', false, /\.(png|jpe?g|svg)$/));

  return images;
}

function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

function createImageArray() {
  const allImages = getImageUrls();

  return allImages.map((imgPath) => ({
    timestamp: randomDate(new Date(2022, 10, 24), new Date()),
    path: imgPath,
  }));
}

function loadRandomImage() {
  const images = createImageArray();
  const item = images[Math.floor(Math.random() * images.length)];

  const imgEl = document.getElementById('voidimage');
  imgEl.classList.add('opacity-0');

  const textEl = document.getElementById('title');
  textEl.innerText = item.timestamp;

  setTimeout(() => {
    imgEl.src = item.path;
  }, transitionTime);

  setTimeout(() => {
    imgEl.src = item.path;
    imgEl.classList.remove('opacity-0');
    imgEl.classList.add('opacity-1');
  }, bufferTime);
}

function stopInterval() {
  if (!interval) return;

  clearInterval(interval, 0);
  interval = undefined;
}

function startInterval() {
  if (interval) return;

  interval = setInterval(() => {
    loadRandomImage();
  }, imageTime);
}

function closeModal(el) {
  el.classList.add('opacity-0');

  setTimeout(() => {
    el.classList.add('hidden');
    el.classList.remove('flex');
  }, modalTransitionTime);
}

function openModal(el) {
  el.classList.remove('hidden');
  el.classList.add('flex');

  setTimeout(() => {
    el.classList.remove('opacity-0');
  }, modalTransitionTime);
}

function initControl() {
  const modal = document.getElementById('modal');
  const button = document.getElementById('control');
  button.addEventListener('click', () => {
    if (interval) {
      stopInterval();
    } else {
      startInterval();
    }
    button.textContent = interval ? '■' : '▸';
  });

  const closemodal = document.getElementById('closemodal');
  closemodal.addEventListener('click', () => {
    closeModal(modal);
  });

  const openmodal = document.getElementById('about');
  openmodal.addEventListener('click', () => {
    if (modal.classList.contains('opacity-0')) {
      openModal(modal);
    } else {
      closeModal(modal);
    }
  });
}

function init() {
  loadRandomImage();
  startInterval();
  initControl();
}

init();
